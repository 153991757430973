import React from "react";
import AlertRow from "./AlertRow";
import { ContentCard, Flex, Text } from "@common/components";

interface AlertSectionProps {}

const AlertSection = ({}: AlertSectionProps) => {
  return (
    <ContentCard>
      <Text size={"1.5rem"} weight={"bold"}>
        Alerts
      </Text>

      <Flex flexDirection={"column"}>
        <AlertRow text={"프로젝트가 아직 게시되지 않았습니다"} />
      </Flex>
    </ContentCard>
  );
};

export default AlertSection;
