import React from "react";
import DashboardCard from "./DashboardCard";
import { FilledButton, Flex, Text } from "@common/components";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { toast } from "react-toastify";

interface DashboardFormLinkCardProps {}

const DashboardFormLinkCard = ({}: DashboardFormLinkCardProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://form.application.gistory.me/?uuid=${selectedProjectUuid}`
    );
    toast.success("복사되었습니다!");
  };

  return (
    <DashboardCard style={{ flex: "1 1 0", aspectRatio: "1.618" }}>
      <DashboardCard.Title>지원서 링크</DashboardCard.Title>

      <Flex
        style={{ width: "100%", flexGrow: 1 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <FilledButton onClick={handleCopy}>
          <Text>복사하기</Text>
        </FilledButton>
      </Flex>
    </DashboardCard>
  );
};

export default DashboardFormLinkCard;
