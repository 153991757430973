import axios from "axios";
import { Application, Subproject } from "@common/types";
import { getDetailedProject } from "./projectAPI";

export const getApplicants = async () => {
  const { data } = await axios.get(`/applicant`);

  return data;
};

export const findApplicant = async ({
  queryKey,
}: {
  queryKey: [string, string];
}) => {
  const [, studentID] = queryKey;

  const { data } = await axios.get(`/applicant/${studentID}`);

  return data;
};

export const updateApplicant = async ({
  mutationKey,
}: {
  mutationKey: [string, string, string];
}) => {
  const [studentID, phoneNumber, email] = mutationKey;

  const body = {
    phoneNumber,
    email,
  };
  const { data } = await axios.patch(`/applicant/${studentID}`, body);

  return data;
};

export const deleteApplicant = async ({
  mutationKey,
}: {
  mutationKey: [string, string];
}) => {
  const [studentID] = mutationKey;

  const { data } = await axios.delete(`/applicant/${studentID}`);

  return data;
};

export const setInterviewTime = async ({
  mutationKey,
}: {
  mutationKey: [string, string, Date];
}) => {
  const [studentID, subprojectUuid, interviewTime] = mutationKey;

  const body = {
    interviewTime: interviewTime.toISOString(),
  };
  const { data } = await axios.post(
    `/application?student_id=${studentID}&subproject_uuid=${subprojectUuid}`,
    body
  );

  return data;
};

export const updateInterviewTime = async ({
  mutationKey,
}: {
  mutationKey: [string, Date];
}) => {
  const [applicationUuid, interviewTime] = mutationKey;

  const body = {
    interviewTime: interviewTime.toISOString(),
  };
  const { data } = await axios.put(`/application/${applicationUuid}`, body);

  return data;
};

export const deleteInterviewTime = async ({
  mutationKey,
}: {
  mutationKey: [string];
}) => {
  const [applicationUuid] = mutationKey;

  const { data } = await axios.delete(`/application/${applicationUuid}`);

  return data;
};

export const getApplications = async ({
  queryKey,
}: {
  queryKey: [string];
}): Promise<Application[]> => {
  const [,] = queryKey;

  const { data } = await axios.get(`/application`);

  return data;
};

export const getApplication = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<Application> => {
  const [, applicationUuid] = queryKey;

  const { data } = await axios.get(`/application/${applicationUuid}`);

  return {
    ...data,
    interviewTime: new Date(data.interviewTime),
  };
};

export const getApplicationsByProject = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<Application[]> => {
  const [, projectUuid] = queryKey;

  const { subprojects } = await getDetailedProject({
    queryKey: ["", projectUuid],
  });

  const applications = await getApplications({
    queryKey: [""],
  });

  const detailedApplications = await Promise.all(
    applications.map(async (application) => {
      const detail = await getApplication({
        queryKey: ["", application.uuid],
      });

      return {
        ...application,
        ...detail,
      };
    })
  );

  if (!subprojects) return [];

  return detailedApplications.filter((application) => {
    return subprojects.some((subproject) => {
      return subproject.uuid === application.subproject?.uuid;
    });
  });
};

interface SubprojectWithApplicationCount extends Subproject {
  applicationCount: number;
}

export const countApplicationBySubproject = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<SubprojectWithApplicationCount[]> => {
  const [, projectUuid] = queryKey;

  const { subprojects } = await getDetailedProject({
    queryKey: ["", projectUuid],
  });

  const subprojectWithApplicationCount = subprojects?.map((subproject) => {
    return {
      ...subproject,
      applicationCount: 0,
    };
  });

  const applications = await getApplications({
    queryKey: [""],
  });

  const detailedApplications = await Promise.all(
    applications.map(async (application) => {
      const detail = await getApplication({
        queryKey: ["", application.uuid],
      });

      return {
        ...application,
        ...detail,
      };
    })
  );

  if (!subprojectWithApplicationCount) return [];

  //count applications by subproject

  detailedApplications.forEach((application) => {
    subprojectWithApplicationCount.forEach((subproject) => {
      if (subproject.uuid === application.subproject?.uuid) {
        subproject.applicationCount++;
      }
    });
  });

  return subprojectWithApplicationCount;
};
