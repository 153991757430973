import React, { CSSProperties } from "react";
import styled from "styled-components";
import { fontFamily } from "@common/theme";

export interface ButtonProps {
  width?: React.CSSProperties["width"];
  height?: string;
  borderRadius?: CSSProperties["borderRadius"];
  color?: string;
  font?: CSSProperties["fontFamily"];
  fontSize?: CSSProperties["fontSize"];
  fontWeight?: CSSProperties["fontWeight"];
  disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  margin: 0;
  padding: 0;
  border: none;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : null)};
  background: none;
  color: ${({ color }) => (color ? color : "inherit")};
  font-family: ${({ font }) => font ?? fontFamily.notoSans};
  font-size: ${({ fontSize }) => fontSize ?? "1rem"};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  :hover {
    cursor: pointer;
  }
`;

export default Button;
