import React, { useEffect } from "react";
import { Content, FilledButton, Flex, Padding } from "@common/components";
import { Subproject, SubprojectQuestion } from "@common/types";
import styled from "styled-components";
import DashboardCard from "../dashboard/DashboardCard";
import { modalListAtom, selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import QuestionCreateModal from "./QuestionCreateModal";
import SubprojectQuestionListItem from "./SubprojectQuestionListItem";

interface SubprojectQuestionListSectionProps {
  subproject: Subproject;
}

const QuestionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  gap: 8px;
`;

const SubprojectQuestionListSection = ({
  subproject,
}: SubprojectQuestionListSectionProps) => {
  const [modalList, setModalList] = useAtom(modalListAtom);
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [sortedQuestions, setSortedQuestions] = React.useState<
    SubprojectQuestion[]
  >([]);

  useEffect(() => {
    setQuestionIndex(subproject.questions?.length || 0);
  }, [subproject.questions?.length]);

  useEffect(() => {
    if (subproject.questions) {
      setSortedQuestions(
        subproject.questions.sort((a, b) => {
          const aIndex = Number(a.question.split(".")[0].replace("Q", ""));
          const bIndex = Number(b.question.split(".")[0].replace("Q", ""));
          return aIndex - bIndex;
        })
      );
    }
  }, [subproject.questions]);

  const handleAddClick = () => {
    setModalList([
      ...modalList,
      <QuestionCreateModal
        questionIndex={questionIndex}
        subprojectUuid={subproject.uuid}
        projectUuid={selectedProjectUuid || ""}
      />,
    ]);
  };

  //TODO: 질문 컴포넌트 분리
  return (
    <Content>
      <DashboardCard>
        <DashboardCard.Title>질문 목록</DashboardCard.Title>
        <QuestionContainer>
          {sortedQuestions.map((question) => (
            <SubprojectQuestionListItem
              question={question}
              key={question.uuid}
            />
          ))}
        </QuestionContainer>

        <Padding height={"20px"} />

        <Flex justifyContent={"end"}>
          <FilledButton onClick={handleAddClick}>질문 추가</FilledButton>
        </Flex>
      </DashboardCard>
    </Content>
  );
};

export default SubprojectQuestionListSection;
