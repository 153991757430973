import React from "react";
import DashboardCard from "../../dashboard/DashboardCard";
import {
  Area,
  Checkbox,
  Content,
  FilledButton,
  Flex,
  Grid,
  Input,
  Text,
} from "@common/components";

const QuestionPage = () => {
  return (
    <Area>
      <Content>
        <DashboardCard>
          <DashboardCard.Title>+ 질문 추가</DashboardCard.Title>
          <Grid gridTemplateColumns={"80px 1fr"} gap={"10px 0"}>
            <Text style={{ lineHeight: "38px" }}>한국어</Text>
            <Input />
            <Text style={{ lineHeight: "38px" }}>영어</Text>
            <Input />
          </Grid>
          <Text>대상 분과:</Text>
          <Flex gap={"10px"}>
            <Checkbox label={<Text>분과 1</Text>} />
            <Checkbox label={<Text>분과 2</Text>} />
            <Checkbox label={<Text>분과 3</Text>} />
          </Flex>
          <Flex justifyContent={"end"} gap={"6px"}>
            <FilledButton width={"90px"} height={"32px"}>
              Reset
            </FilledButton>
            <FilledButton
              width={"90px"}
              height={"32px"}
              backgroundColor={"blue"}
            >
              Add
            </FilledButton>
          </Flex>
        </DashboardCard>
      </Content>
    </Area>
  );
};

export default QuestionPage;
