import React from "react";
import {
  ContentCard,
  FilledButton,
  Flex,
  MaterialInput,
  Text,
} from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addManagerWithEmail } from "../../apis/managerAPI";
import { queryKeys } from "../../utils/keys";
import { toast } from "react-toastify";

interface ManagerAddSectionProps {}

const ManagerAddSection = ({}: ManagerAddSectionProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const emailRef = React.useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const add = useMutation(addManagerWithEmail, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([queryKeys.project]);
    },
    onError: (error) => {
      toast.error(
        "존재하지 않는 사용자입니다. AMS 로그인 이력이 있는지 확인해주세요."
      );
    },
  });

  const handleAddClick = () => {
    add.mutate({
      mutationKey: [selectedProjectUuid || "", emailRef.current!.value],
    });
  };

  return (
    <ContentCard>
      <ContentCard.Title>+ 관리자 추가</ContentCard.Title>

      <Flex>
        <MaterialInput label={"이메일"} inputRef={emailRef} />
      </Flex>

      <Flex gap={"10px"} justifyContent={"end"}>
        <FilledButton
          width={"100px"}
          borderRadius={"5px"}
          backgroundColor={"#6565FF"}
          hoverBackgroundColor={"#8282FF"}
          onClick={handleAddClick}
        >
          <Text>Add</Text>
        </FilledButton>
      </Flex>
    </ContentCard>
  );
};

export default ManagerAddSection;
