import React from "react";
import MessageWriteCard from "../configure/MessageWriteCard";
import MessageTargetCard from "../configure/MessageTargetCard";
import { Area, Content } from "@common/components";

const MessageSendPage = () => {
  return (
    <Area>
      <Content>
        <MessageWriteCard />
        <MessageTargetCard />
      </Content>
    </Area>
  );
};

export default MessageSendPage;
