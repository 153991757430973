import React from "react";
import { MaterialInput, Modal, Padding } from "@common/components";
import { modalListAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutationKeys, queryKeys } from "../../utils/keys";
import { createSubprojectQuestion } from "../../apis/subprojectAPI";

interface QuestionCreateModalProps {
  subprojectUuid: string;
  projectUuid: string;
  questionIndex: number;
}

const QuestionCreateModal = ({
  subprojectUuid,
  projectUuid,
  questionIndex,
}: QuestionCreateModalProps) => {
  const [, setModalList] = useAtom(modalListAtom);
  const questionRef = React.useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const addQuestion = useMutation(
    [mutationKeys.createSubprojectQuestion],
    createSubprojectQuestion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.subproject]);

        setModalList((prev) => prev.slice(0, -1));
      },
    }
  );

  const handleAddClick = () => {
    if (!questionRef.current?.value) {
      alert("질문을 입력해주세요.");
      return;
    }

    addQuestion.mutate({
      mutationKey: [
        subprojectUuid,
        projectUuid,
        `Q${questionIndex + 1}. ${questionRef.current?.value || ""}`,
      ],
    });
  };

  return (
    <Modal
      onClose={() => {
        setModalList((prev) => prev.slice(0, -1));
      }}
    >
      <Modal.Title text={"질문 추가"} />

      <MaterialInput label={"질문"} width={"100%"} inputRef={questionRef} />

      <Padding height={"20px"} />

      <Modal.ButtonContainer>
        <Modal.Button
          text={"취소"}
          onClick={() => {
            setModalList((prev) => prev.slice(0, -1));
          }}
        />
        <Modal.Button text={"추가"} onClick={handleAddClick} />
      </Modal.ButtonContainer>
    </Modal>
  );
};

export default QuestionCreateModal;
