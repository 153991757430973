import React from "react";
import AutoMessageRow from "./AutoMessageRow";
import DashboardCard from "../../dashboard/DashboardCard";
import { Area, Content, Divider, Grid } from "@common/components";

const MessageConfigurePage = () => {
  return (
    <Area>
      <Content>
        <DashboardCard>
          <DashboardCard.Title>메시지 목록</DashboardCard.Title>

          <Grid style={{ marginTop: "16px" }}>
            <AutoMessageRow text={"지원 완료 메시지"} />
            <Divider />
            <AutoMessageRow text={"면접 리마인드"} />
            <Divider />
            <AutoMessageRow text={"면접 안내 메시지"} />
          </Grid>
        </DashboardCard>
      </Content>
    </Area>
  );
};

export default MessageConfigurePage;
