import React from "react";
import SubprojectAddSection from "./SubprojectAddSection";
import SubprojectListSection from "./SubprojectListSection";
import { Area, Padding } from "@common/components";

const SubprojectPage = () => {
  return (
    <Area>
      <SubprojectAddSection />

      <Padding height={"40px"} />

      <SubprojectListSection />
    </Area>
  );
};

export default SubprojectPage;
