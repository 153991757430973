import React from "react";
import { Flex, Text } from "@common/components";
import { queryKeys } from "../../utils/keys";
import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "../../apis/userAPI";

interface ManagerListRowProps {
  userUuid: string;
}

const ManagerListRow = ({ userUuid }: ManagerListRowProps) => {
  const { data } = useQuery([queryKeys.user, userUuid], getUserInfo);

  if (!data) return null;

  return (
    <Flex
      alignItems={"center"}
      gap={"5px"}
      style={{
        border: "1px solid #999999",
        padding: "10px 10px",
        borderRadius: "8px",
        fontWeight: 500,
      }}
    >
      <Text>{data.user_name}</Text>
      <Text>{data.user_email_id}</Text>
    </Flex>
  );
};

export default ManagerListRow;
