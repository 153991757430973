import React from "react";
import ManagerListRow from "./ManagerListRow";
import { ContentCard, Grid } from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getDetailedProject } from "../../apis/projectAPI";

interface ManagerListSectionProps {}

const ManagerListSection = ({}: ManagerListSectionProps) => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useAtom(
    selectedProjectUuidAtom
  );

  const { data } = useQuery(
    [queryKeys.project, selectedProjectUuid || ""],
    getDetailedProject
  );

  return (
    <ContentCard>
      <ContentCard.Title>Managers</ContentCard.Title>

      <Grid gap={"10px"}>
        {data?.manager?.map((manager) => (
          <ManagerListRow userUuid={manager.userUUID} />
        ))}
      </Grid>
    </ContentCard>
  );
};

export default ManagerListSection;
