import styled from "styled-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Text } from "@common/components";
import { colorSet } from "@common/theme";
import axios from "axios";
import { useAtom } from "jotai";
import { isLoggedInAtom } from "../utils/store";

const HeaderContainer = styled.div`
  position: fixed;
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;

  overflow: visible;

  z-index: 100;
`;

interface HeaderMenuContainerProps {
  submenuHeight?: React.CSSProperties["height"];
}

const HeaderMenuContainer = styled.div<HeaderMenuContainerProps>`
  height: 50px;
  color: white;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  transition: height 0.5s;

  flex: 1 1 0;

  overflow: hidden;

  background-color: ${colorSet.primary};

  :hover {
    height: ${({ submenuHeight }) => submenuHeight ?? "auto"};
  }
`;

interface HeaderMenuProps {
  menu: string;
  to: string;
  subMenu?: { menu: string; to: string }[];
  subMenuHeight?: React.CSSProperties["height"];
}

const HeaderMenu = ({ menu, to, subMenu, subMenuHeight }: HeaderMenuProps) => {
  const navigate = useNavigate();

  return (
    <HeaderMenuContainer submenuHeight={subMenuHeight}>
      <Button
        onClick={() => navigate(to)}
        height={"50px"}
        style={{ minHeight: "50px" }}
      >
        {menu}
      </Button>

      {subMenu?.map((subMenu) => (
        <Button
          onClick={() => navigate(subMenu.to)}
          key={subMenu.menu}
          fontSize={"1rem"}
          style={{
            padding: "10px",
          }}
        >
          {subMenu.menu}
        </Button>
      ))}
    </HeaderMenuContainer>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessTokenExp");
    axios.defaults.headers.common["Authorization"] = "";
    setIsLoggedIn(false);
    navigate("/sign-in");
  };

  return (
    <HeaderContainer>
      <Button
        onClick={() => {
          navigate("/");
        }}
        style={{
          backgroundColor: colorSet.primary,
          height: "50px",
          color: "white",
          padding: "0 20px",
        }}
      >
        <Text size={"1.5rem"} weight={"bold"}>
          GIST AMS
        </Text>
      </Button>

      {location.pathname.startsWith("/project/") ? (
        <Flex
          style={{
            flexGrow: 2,
          }}
        >
          <HeaderMenu
            menu={"Project"}
            to={"/project/dashboard"}
            subMenu={[
              { menu: "대시보드", to: "/project/dashboard" },
              { menu: "관리자 관리", to: "/project/manager" },
              { menu: "분과 관리", to: "/project/subproject" },
            ]}
          />
          <HeaderMenu
            menu={"Application"}
            to={"/project/application"}
            subMenu={[
              { menu: "지원자 목록", to: "/project/application/form" },
              { menu: "면접 스케쥴", to: "/project/schedule" },
            ]}
          />
          <HeaderMenu
            menu={"Message"}
            to={"/project/message"}
            subMenu={[
              { menu: "Send", to: "/project/message/send" },
              {
                menu: "Configure Auto Messages",
                to: "/project/message/auto",
              },
            ]}
          />
        </Flex>
      ) : null}

      <div
        style={{
          flexGrow: 1,
          height: "50px",
          backgroundColor: colorSet.primary,
        }}
      />

      <Flex
        gap={"10px"}
        style={{
          paddingRight: "20px",
          backgroundColor: colorSet.primary,
          height: "50px",
        }}
      >
        <Flex flexDirection={"column"} justifyContent={"center"}>
          {isLoggedIn ? (
            <Button onClick={handleLogout}>
              <Text size={"1rem"} color={"white"}>
                {t("layout.header.setting.logout")}
              </Text>
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </HeaderContainer>
  );
};

export default Header;
