import React from "react";
import DashboardCard from "./DashboardCard";
import { Flex, Text } from "@common/components";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { countApplicationBySubproject } from "../../apis/applicantAPI";

interface DashboardSubprojectCardProps {}

const DashboardSubprojectCard = ({}: DashboardSubprojectCardProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.applicationCountBySubproject, selectedProjectUuid || ""],
    countApplicationBySubproject
  );

  if (!data) return null;

  return (
    <DashboardCard
      to={"/project/subproject"}
      style={{ flex: "1 1 0", aspectRatio: "1.618" }}
    >
      <DashboardCard.Title>분과별 지원 현황</DashboardCard.Title>

      <Flex
        style={{
          width: "100%",
          flexGrow: 1,
          marginTop: "15px",
          overflowY: "auto",
        }}
        gap={"8px"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"stretch"}
      >
        {data?.map((subproject) => (
          <Flex justifyContent={"space-between"}>
            <Text size={"1rem"} weight={500}>
              {subproject.name}
            </Text>
            <Text>{subproject.applicationCount} 명</Text>
          </Flex>
        ))}
      </Flex>
    </DashboardCard>
  );
};

export default DashboardSubprojectCard;
