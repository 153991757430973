import React from "react";
import ApplicantPersonalQuestionCard from "./ApplicantPersonalQuestionCard";
import { Grid } from "@common/components";
import { SubprojectAnswer } from "@common/types";

interface ApplicantPersonalQuestionCardProps {
  answers: SubprojectAnswer[];
}

const ApplicantPersonalQuestionCardList = ({
  answers,
}: ApplicantPersonalQuestionCardProps) => {
  return (
    <Grid gap={"10px 0"}>
      {answers.map((answer) => (
        <ApplicantPersonalQuestionCard
          question={answer.question.question}
          answer={answer.answer}
        />
      ))}
    </Grid>
  );
};

export default ApplicantPersonalQuestionCardList;
