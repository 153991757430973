import React from "react";
import ManagerAddSection from "./ManagerAddSection";
import ManagerListSection from "./ManagerListSection";
import { Area, Padding } from "@common/components";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getDetailedProject } from "../../apis/projectAPI";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { getMyUuid } from "../../apis/userAPI";

const ManagerPage = () => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.project, selectedProjectUuid || ""],
    getDetailedProject
  );

  const { data: userUuid } = useQuery([queryKeys.myUuid], getMyUuid);

  if (!data || !userUuid) {
    return null;
  }

  return (
    <Area>
      <Padding height={"20px"} />

      {data.owner?.userUUID === userUuid.user_uuid && <ManagerAddSection />}

      <Padding height={"40px"} />

      <ManagerListSection />
    </Area>
  );
};

export default ManagerPage;
