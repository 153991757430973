import React, { useReducer } from "react";
import { useAtom } from "jotai";
import { modalListAtom } from "../../utils/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProject } from "../../apis/projectAPI";
import { Grid, MaterialInput, Modal, Text } from "@common/components";
import { Project } from "@common/types";
import { queryKeys } from "../../utils/keys";
import { toast } from "react-toastify";

interface ProjectCreateModalProps {}

interface actionType {
  type: "SET_NAME" | "SET_START_DATE" | "SET_END_DATE";
  payload: string | Date;
}

const ProjectCreateModal = ({}: ProjectCreateModalProps) => {
  const [, setModalList] = useAtom(modalListAtom);
  const queryClient = useQueryClient();
  const create = useMutation(createProject, {
    onSuccess: (data) => {
      setModalList((prev) => prev.slice(0, -1));
      queryClient.invalidateQueries([queryKeys.projects]);
    },
    onError: (error) => {
      toast.error("프로젝트 생성에 실패했습니다.");
    },
  });

  const reducer = (state: Project, action: actionType) => {
    switch (action.type) {
      case "SET_NAME":
        return { ...state, name: action.payload as string };
      case "SET_START_DATE":
        return { ...state, startDate: new Date(action.payload) };
      case "SET_END_DATE":
        return { ...state, endDate: new Date(action.payload) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    name: "",
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleCreateClick = () => {
    if (state.name === "") {
      toast.error("프로젝트 이름을 입력해주세요.");
      return;
    }

    create.mutate({
      mutationKey: [state.name, state.startDate, state.endDate],
    });
  };

  return (
    <Modal onClose={() => setModalList((prev) => prev.slice(0, -1))}>
      <Modal.Title text={"프로젝트 생성"} />

      <Grid
        gridTemplateColumns={"100px 1fr"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"20px"}
      >
        <Text
          style={{
            marginTop: "15px",
          }}
        >
          프로젝트 이름
        </Text>
        <MaterialInput
          label={"프로젝트 이름"}
          inputProps={{
            onBlur: (e) => {
              dispatch({
                type: "SET_NAME",
                payload: e.target.value,
              });
            },
          }}
        />

        <Text>시작일</Text>
        <input
          type={"date"}
          onChange={(e) => {
            dispatch({
              type: "SET_START_DATE",
              payload: e.target.value,
            });
          }}
        />

        <Text>종료일</Text>
        <input
          type={"date"}
          onChange={(e) => {
            dispatch({
              type: "SET_END_DATE",
              payload: e.target.value,
            });
          }}
        />
      </Grid>

      <Modal.ButtonContainer
        style={{
          marginTop: "20px",
        }}
      >
        <Modal.Button text={"생성"} onClick={handleCreateClick} />
      </Modal.ButtonContainer>
    </Modal>
  );
};

export default ProjectCreateModal;
