export const queryKeys = {
  projects: "getProjects",
  project: "getProject",
  subprojects: "getSubprojects",
  subproject: "getSubproject",
  findUser: "findUser",
  managers: "getManagers",
  user: "getUser",
  applicants: "getApplicants",
  application: "getApplication",
  applications: "getApplications",
  applicationsByProject: "getApplicationsByProject",
  applicationCountBySubproject: "getApplicationCountBySubproject",
  myUuid: "getMyUuid",
};

export const mutationKeys = {
  createProject: "createProject",
  updateProjectName: "updateProjectName",
  deleteProject: "deleteProject",
  createSubproject: "createSubproject",
  createSubprojectQuestion: "createSubprojectQuestion",
};
