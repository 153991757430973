import React from "react";
import styled from "styled-components";
import { colorSet } from "@common/theme";
import { Button, Text } from "@common/components";
import { useNavigate } from "react-router-dom";

const DashboardCardContainer = styled.div`
  position: relative;
  background-color: white;
  color: ${colorSet.primaryText};

  padding: 24px;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 20%) 0 0 2px 0,
    rgb(145 158 171 / 12%) 0 12px 24px -4px;

  display: flex;
  flex-direction: column;
`;

interface DashboardCardProps {
  children?: React.ReactNode;
  to?: string;
  style?: React.CSSProperties;
}

const DashboardCard = ({ children, to, style }: DashboardCardProps) => {
  const navigate = useNavigate();

  return (
    <DashboardCardContainer style={style}>
      {children}

      {to && (
        <Button onClick={() => navigate(to)}>
          <Text
            size={"0.875rem"}
            weight={500}
            style={{
              margin: "16px 0 0 0",
              textAlign: "right",
              position: "absolute",
              bottom: "14px",
              right: "18px",
            }}
          >
            View more
          </Text>
        </Button>
      )}
    </DashboardCardContainer>
  );
};

interface Title {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Title = ({ children, style }: Title) => {
  return (
    <Text
      size={"1.125rem"}
      weight={500}
      style={{ margin: "-10px 0 16px 0", ...style }}
    >
      {children}
    </Text>
  );
};

DashboardCard.Title = Title;

export default DashboardCard;
