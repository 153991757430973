import React from "react";
import { Area, Content, PageTitle } from "@common/components";
import { useAtom } from "jotai";
import { selectedSubprojectUuidAtom } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import SubprojectQuestionListSection from "./SubprojectQuestionListSection";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getSubproject } from "../../apis/subprojectAPI";

const SubprojectDetailPage = () => {
  const [selectedSubprojectUuid] = useAtom(selectedSubprojectUuidAtom);

  const { data } = useQuery(
    [queryKeys.subproject, selectedSubprojectUuid || ""],
    getSubproject
  );

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!selectedSubproject) {
  //     alert("잘못된 접근입니다.");
  //
  //     navigate("/subproject");
  //   }
  //
  //   return () => {
  //     setSelectedSubproject(null);
  //   };
  // }, []);

  if (!data) {
    return null;
  }

  return (
    <Area>
      <Content>
        <PageTitle title={data.name} />
      </Content>
      <SubprojectQuestionListSection subproject={data} />
    </Area>
  );
};

export default SubprojectDetailPage;
