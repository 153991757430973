import { useAtom } from "jotai";
import { isLoggedInAtom, selectedProjectUuidAtom } from "./store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { loginWithIdp } from "../apis/userAPI";
import axios from "axios";

export const useSelectedProject = () => {
  const [, setSelectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("selectedProject") != null) {
      setSelectedProjectUuid(localStorage.getItem("selectedProject"));
    } else {
      setSelectedProjectUuid(null);
      navigate("/project-list");
    }
  }, []);
};

export const useLogin = () => {
  const [, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      const authCode = searchParams.get("auth_code");

      if (authCode) {
        await loginWithIdp(authCode);
        searchParams.delete("auth_code");
        setSearchParams(searchParams);
      } //TODO authCode는 일회용이므로 새로고침/뒤로가기 대응 필요

      //check if token is expired
      if (
        localStorage.getItem("accessTokenExp") &&
        localStorage.getItem("accessToken") &&
        Date.now() < parseInt(localStorage.getItem("accessTokenExp") || "0")
      ) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("accessToken")}`;

        setIsLoggedIn(true);
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accessTokenExp");
        setIsLoggedIn(false);
        navigate("/login");
      }
    };

    fetchData();
  }, []);
};

export const useProject = () => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useAtom(
    selectedProjectUuidAtom
  );

  useEffect(() => {
    const savedProject = localStorage.getItem("selectedProjectUuid");

    if (savedProject) {
      setSelectedProjectUuid(savedProject);
    }
  }, [selectedProjectUuid]);
};
