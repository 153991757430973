import React from "react";
import SubprojectListRow from "./SubprojectListRow";
import { ContentCard, Grid } from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getSubprojects } from "../../apis/subprojectAPI";

interface SubprojectListSectionProps {}

const SubprojectListSection = ({}: SubprojectListSectionProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.subprojects, selectedProjectUuid || ""],
    getSubprojects
  );

  return (
    <ContentCard>
      <ContentCard.Title>Subprojects</ContentCard.Title>
      <Grid gap={"5px"}>
        {data ? (
          <>
            {data.map((subproject) => (
              <SubprojectListRow
                subproject={subproject}
                key={subproject.uuid}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </ContentCard>
  );
};

export default SubprojectListSection;
