import React from "react";
import DashboardCard from "../../../dashboard/DashboardCard";
import { Grid, Text } from "@common/components";
import { Application } from "@common/types";
import { nullDate } from "../../../../utils/util";

interface ApplicantPersonalInfoCardProps {
  application: Application;
}

const ApplicantPersonalInfoCard = ({
  application,
}: ApplicantPersonalInfoCardProps) => {
  return (
    <DashboardCard>
      <DashboardCard.Title>기본 정보</DashboardCard.Title>

      <Grid
        gridTemplateColumns={"1fr 1fr"}
        gap={"10px 0"}
        style={{ margin: "10px 0 15px 0" }}
      >
        <Text>{`이름: ${application.applicant?.name}`}</Text>
        <Text>{`학번: ${application.applicant?.studentID}`}</Text>
        <Text>{`연락처: ${application.applicant?.phoneNumber}`}</Text>
        <Text>{`지원 구분: ${application.subproject?.name}`}</Text>
      </Grid>
      <Text>{`면접 일정: ${
        application.interviewTime > nullDate
          ? application.interviewTime.toLocaleString()
          : "미정"
      }`}</Text>
      {/*<Text*/}
      {/*  style={{*/}
      {/*    marginTop: "15px",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  태그*/}
      {/*</Text>*/}
      {/*<Grid></Grid>*/}
    </DashboardCard>
  );
};

export default ApplicantPersonalInfoCard;
