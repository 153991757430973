import React, { useEffect } from "react";
import ApplicantPersonalInfoCard from "./ApplicantPersonalInfoCard";
import ApplicantPersonalQuestionCardList from "./ApplicantPersonalQuestionCardList";
import { Area, Content, Grid, PageTitle } from "@common/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryKeys } from "../../../../utils/keys";
import { useQuery } from "@tanstack/react-query";
import { getApplication } from "../../../../apis/applicantAPI";
import { SubprojectAnswer } from "@common/types";

const ApplicantPersonalPage = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [sortedAnswers, setSortedAnswers] = React.useState<SubprojectAnswer[]>(
    []
  );

  if (!query.get("uuid")) {
    alert("잘못된 접근입니다.");
    navigate(-1);
    return null;
  }

  const { data } = useQuery(
    [queryKeys.application, query.get("uuid") || ""],
    getApplication
  );

  useEffect(() => {
    if (data?.answers) {
      const sorted = data.answers.sort((a, b) => {
        if (a.question.question < b.question.question) {
          return -1;
        }
        if (a.question.question > b.question.question) {
          return 1;
        }
        return 0;
      });

      setSortedAnswers(sorted);
    }
  }, [data]);

  if (!data) return null;

  return (
    <Area>
      <Content>
        <PageTitle title="지원서 (개인)" onClick={() => navigate(-1)} />
      </Content>
      <Content>
        <ApplicantPersonalInfoCard application={data} />

        <Grid
          gridTemplateColumns={"1fr"}
          gap={"0 15px"}
          style={{ marginTop: "15px" }}
        >
          <ApplicantPersonalQuestionCardList answers={data.answers || []} />
          {/*<ApplicantPersonalMemoCard />*/}
        </Grid>
      </Content>
    </Area>
  );
};

export default ApplicantPersonalPage;
