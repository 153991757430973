import React from "react";
import { ContentCard, Grid, Text } from "@common/components";
import { useQuery } from "@tanstack/react-query";
import { getDetailedProjects } from "../../apis/projectAPI";

interface OverallSectionProps {}

const OverallSection = ({}: OverallSectionProps) => {
  const { data } = useQuery([], getDetailedProjects);

  return (
    <ContentCard>
      <Text size={"1.5rem"} weight={"bold"} style={{}}>
        Overview
      </Text>

      <Grid
        gridTemplateColumns={"1fr 1fr"}
        style={{ margin: "5px 0 20px 20px" }}
      >
        <Text>Project ID: (프로젝트 ID)</Text>
        <Text>Name: (프로젝트 이름)</Text>
        <Text>Status: (프로젝트 상태)</Text>
        <Text>Duration: YYYY.MM.DD~YYYY.MM.DD</Text>
        <Text>Project Owner: (계정 ID/메일/이름)</Text>
        <Text>Owner Phone No: 010-0000-0000</Text>
      </Grid>

      <Text size={"1.5rem"} weight={"bold"}>
        Application
      </Text>

      <Grid
        gridTemplateColumns={"1fr 1fr"}
        style={{ margin: "5px 0 20px 20px" }}
      >
        <Text>Duration: YYYY.MM.DD~YYYY.MM.DD</Text>
      </Grid>

      <Text size={"1.5rem"} weight={"bold"}>
        Interview
      </Text>

      <Grid
        gridTemplateColumns={"1fr 1fr"}
        style={{ margin: "5px 0 20px 20px" }}
      >
        <Text>Duration: YYYY.MM.DD~YYYY.MM.DD</Text>
      </Grid>

      <Text size={"1.5rem"} weight={"bold"}>
        Result Announcement
      </Text>

      <Grid
        gridTemplateColumns={"1fr 1fr"}
        style={{ margin: "5px 0 20px 20px" }}
      >
        <Text>Date: YYYY.MM.DD</Text>
        <Text>Time: HH:MM</Text>
      </Grid>
    </ContentCard>
  );
};

export default OverallSection;
