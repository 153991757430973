import React from "react";
import { useNavigate } from "react-router-dom";
import { Area, FilledButton, Flex, Text } from "@common/components";
import { useAtom } from "jotai";
import { isLoggedInAtom } from "../../utils/store";

const SignInPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const navigate = useNavigate();

  const handleSignInBtnClick = () => {
    // setIsLoggedIn(true);
    // navigate("/project-list");
  };

  return (
    <Area>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{
          borderRadius: "10px",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
          width: "50%",
          maxWidth: "300px",
          height: "160px",
          marginTop: "180px",
          padding: "20px 0 80px 0",
          backgroundColor: "white",
        }}
      >
        <Text>GIST-AMS</Text>
        <form
          action={`https://gistory-idp-fe.pages.dev`}
          style={{
            width: "60%",
          }}
        >
          <input
            type={"hidden"}
            name={"redirect_uri"}
            value={window.location.host}
          />
          <FilledButton type={"submit"} width={"100%"} height={"50px"}>
            지스토리로 로그인
          </FilledButton>
        </form>
      </Flex>
    </Area>
  );
};

export default SignInPage;
