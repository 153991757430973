export const colorSet = {
  primary: "#0F6292",
  //primary: "#FF6565",
  secondary: "#FF8282",
  primaryLight: "#FFECEC",

  primaryText: "#4C4C4C",
  secondaryText: "#959595",
};

export const CONTENT_AREA_DESKTOP = "75%";
export const CONTENT_AREA_MOBILE = "92%";

export const fontFamily = {
  notoSans: "noto-sans-kr, sans-serif",
};

export type FontFamily = keyof typeof fontFamily;
