import React from "react";
import styled from "styled-components";
import { colorSet } from "@common/theme";

const ListRowContainer = styled.div`
  background-color: white;
  color: ${colorSet.primaryText};

  padding: 16px;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 30%) 0 0 2px 0,
    rgb(145 158 171 / 24%) 0 6px 12px -4px;

  display: flex;
  flex-direction: column;
`;

interface DashboardCardProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const ListRow = ({ children, style }: DashboardCardProps) => {
  return <ListRowContainer style={style}>{children}</ListRowContainer>;
};

export default ListRow;
