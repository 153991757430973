import axios from "axios";
import { findUser } from "./userAPI";
import { queryKeys } from "../utils/keys";

export const addManager = async ({
  mutationKey,
}: {
  mutationKey: [string, string];
}) => {
  const [projectUuid, userUUID] = mutationKey;

  const body = {
    manager_uuid: userUUID,
  };
  const { data } = await axios.patch(`/project/${projectUuid}/manager`, body);

  return data;
};

export const addManagerWithEmail = async ({
  mutationKey,
}: {
  mutationKey: [string, string];
}) => {
  const [projectUuid, email] = mutationKey;

  const { user_uuid } = await findUser({
    queryKey: [queryKeys.findUser, email],
  });

  const body = {
    manager_uuid: user_uuid,
  };
  const { data } = await axios.patch(`/project/${projectUuid}/manager`, body);

  return data;
};
