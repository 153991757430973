import React from "react";
import { Button, FilledButton, Flex, Kebab, Text } from "@common/components";
import {
  selectedProjectUuidAtom,
  selectedSubprojectUuidAtom,
} from "../../utils/store";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { Subproject } from "@common/types";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSubproject } from "../../apis/subprojectAPI";
import { toast } from "react-toastify";
import { queryKeys } from "../../utils/keys";

interface SubprojectListRowProps {
  subproject: Subproject;
}

const SettingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 10px;

  & > button {
    display: none;
  }

  &:hover {
    cursor: pointer;

    & > button {
      display: block;
    }
  }
`; //TODO: 추상화

const SubprojectListRow = ({ subproject }: SubprojectListRowProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const [, setSelectedSubproject] = useAtom(selectedSubprojectUuidAtom);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const subprojectDeletion = useMutation(deleteSubproject, {
    onSuccess: () => {
      toast.success("분과가 삭제되었습니다.");
      queryClient.invalidateQueries([queryKeys.subprojects]);
    },
  });

  const handleClick = () => {
    setSelectedSubproject(subproject.uuid);
    navigate(`/project/subproject/detail`);
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "정말로 삭제하시겠습니까? 분과의 질문과 답변도 삭제됩니다."
      )
    ) {
      subprojectDeletion.mutate({
        mutationKey: [subproject.uuid, selectedProjectUuid || ""],
      });
    }
  };

  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      style={{
        border: "1px solid #999999",
        padding: "5px 5px 5px 10px",
        borderRadius: "8px",
        height: "30px",
      }}
    >
      <Button onClick={handleClick}>
        <Text weight={500}>{subproject.name}</Text>
      </Button>

      <SettingButtonContainer>
        <FilledButton
          onClick={handleDelete}
          size={"small"}
          backgroundColor={"#ff556b"}
        >
          삭제
        </FilledButton>
        <Kebab />
      </SettingButtonContainer>
    </Flex>
  );
};

export default SubprojectListRow;
