import React, { useId } from "react";
import Flex from "../containers/Flex";

export interface CheckboxProps {
  label?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  checkboxStyle?: React.CSSProperties;
}

const Checkbox = ({ label, containerStyle, checkboxStyle }: CheckboxProps) => {
  const id = useId();

  return (
    <Flex style={containerStyle}>
      <input type={"checkbox"} id={id} style={checkboxStyle} />
      {label && <label htmlFor={id}>{label}</label>}
    </Flex>
  );
};

export default Checkbox;
