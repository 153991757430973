import React from "react";

interface ScheduleItemProps {
  data: {
    appointmentData: {
      startDate: Date;
      endDate: Date;
      text: string;
      uuid: string;
    };
  };
}

const ScheduleItem = ({ data }: ScheduleItemProps) => {
  const { appointmentData } = data;

  return <div>{appointmentData.text}</div>;
};

export default ScheduleItem;
