import React from "react";
import DashboardCard from "../../dashboard/DashboardCard";
import { FilledButton, Flex, Grid, Text } from "@common/components";

interface MessageWriteCardProps {}

const MessageWriteCard = ({}: MessageWriteCardProps) => {
  return (
    <DashboardCard>
      <DashboardCard.Title>메시지 작성</DashboardCard.Title>
      <Flex
        justifyContent={"stretch"}
        style={{
          margin: "10px 0",
        }}
      >
        <textarea />
      </Flex>
      <Grid gridTemplateColumns={"1fr 100px 100px 90px 90px"} gap={"6px"}>
        <Flex>
          <FilledButton>
            <Text>도움말</Text>
          </FilledButton>
        </Flex>
        <Text lineHeight={"32px"}>00명 선택됨</Text>
        <Text lineHeight={"32px"}>00/140자</Text>
        <FilledButton width={"90px"} height={"32px"}>
          <Text>Reset</Text>
        </FilledButton>
        <FilledButton width={"90px"} height={"32px"}>
          <Text>Send</Text>
        </FilledButton>
      </Grid>
    </DashboardCard>
  );
};

export default MessageWriteCard;
