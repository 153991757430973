import React from "react";
import DashboardCard from "./DashboardCard";
import { Area, Content, Flex, PageTitle, Text } from "@common/components";
import DashboardApplicationNumber from "./DashboardApplicationNumber";
import DashboardStatusCard from "./DashboardStatusCard";
import DashboardFormLinkCard from "./DashboardFormLinkCard";
import DashboardSubprojectCard from "./DashboardSubprojectCard";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { queryKeys } from "../../utils/keys";
import { useQuery } from "@tanstack/react-query";
import { getDetailedProject } from "../../apis/projectAPI";

const Dashboard = () => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.project, selectedProjectUuid || ""],
    getDetailedProject
  );

  if (!data) return null;

  return (
    <Area>
      <Content>
        <PageTitle title={`Dashboard - ${data.name}`} />

        <Flex gap={"10px"} style={{ width: "100%" }}>
          <DashboardApplicationNumber />

          <DashboardStatusCard />

          <DashboardFormLinkCard />
        </Flex>

        <Flex gap={"10px"} width={"100%"} style={{ marginTop: "20px" }}>
          <DashboardSubprojectCard />
          <DashboardCard
            to={"/project/message/send"}
            style={{ flex: "1 1 0", aspectRatio: "1.618" }}
          >
            <DashboardCard.Title>메시지</DashboardCard.Title>

            <Flex
              style={{
                width: "100%",
                flexGrow: 1,
                overflowY: "auto",
                marginBottom: "25px",
              }}
              gap={"8px"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text size={"1.5rem"} weight={"bold"}>
                🚧 메시지 기능은 현재 개발중입니다! 🚧
              </Text>
            </Flex>
          </DashboardCard>
        </Flex>
      </Content>
    </Area>
  );
};

export default Dashboard;
