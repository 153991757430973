import React, { useEffect } from "react";
import { ContentCard, Grid, Text } from "@common/components";
import { Draggable, Scheduler, ScrollView } from "devextreme-react";
import { AppointmentDragging, Editing } from "devextreme-react/scheduler";
import ScheduleItem from "./ScheduleItem";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { nullDate } from "../../utils/util";

interface ScheduleCardProps {
  schedule: {
    text: string;
    startDate: Date;
    endDate: Date;
    uuid: string;
  }[];
  notAppointed: {
    text: string;
    uuid: string;
  }[];
}

const ScheduleCard = ({ schedule, notAppointed }: ScheduleCardProps) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("schedule", schedule);
  }, [schedule]);

  const onAppointmentRemove = async (e: any) => {
    console.log("remove", e);
    const index = schedule.findIndex(
      (item) => item.uuid === e.appointmentData.uuid
    );

    await axios.put(`/application/${e.appointmentData.uuid}`, {
      interviewTime: nullDate,
    });

    queryClient.invalidateQueries([queryKeys.applicationsByProject]);
  };

  const onAppointmentAdd = async (e: any) => {
    console.log("add", e);
    const index = notAppointed.findIndex(
      (item) => item.uuid === e.itemData.uuid
    );

    console.log("index", index);
    if (index >= 0) {
      await axios.put(`/application/${e.itemData.uuid}`, {
        interviewTime: e.itemData.startDate,
      });

      queryClient.invalidateQueries([queryKeys.applicationsByProject]);
    }
  };

  const onAppointmentUpdate = async (e: any) => {
    console.log("update", e);

    const index = schedule.findIndex(
      (item) => item.uuid === e.appointmentData.uuid
    );

    console.log("index", index);

    if (index >= 0) {
      await axios.put(`/application/${e.appointmentData.uuid}`, {
        interviewTime: e.appointmentData.startDate,
      });
    }
  };

  const onListDragStart = (e: any) => {
    e.cancel = true;
  };

  const onItemDragStart = (e: any) => {
    e.itemData = e.fromData;
  };

  const onItemDragEnd = (e: any) => {
    if (e.toData) {
      e.cancel = true;
    }
  };

  return (
    <ContentCard>
      <Grid gridTemplateColumns={"100px 1fr"} gap={"10px"}>
        <ScrollView id="scroll">
          <Draggable
            id="list"
            data="dropArea"
            group={"면접 일정"}
            onDragStart={onListDragStart}
          >
            {notAppointed.map((task) => (
              <Draggable
                key={task.uuid}
                className="item dx-card dx-theme-text-color dx-theme-background-color"
                clone={true}
                group={"면접 일정"}
                data={task}
                onDragStart={onItemDragStart}
                onDragEnd={onItemDragEnd}
                style={{
                  padding: "10px",
                  margin: "5px 0",
                }}
              >
                <Text>{task.text}</Text>
              </Draggable>
            ))}
          </Draggable>
        </ScrollView>
        <Scheduler
          timeZone="Asia/Seoul"
          dataSource={schedule}
          views={["week"]}
          defaultCurrentView={"week"}
          firstDayOfWeek={1}
          startDayHour={9}
          appointmentComponent={ScheduleItem}
          onAppointmentDeleted={onAppointmentRemove}
          onAppointmentUpdated={onAppointmentUpdate}
        >
          <Editing allowAdding={false} allowResizing={false} />
          <AppointmentDragging
            group={"면접 일정"}
            onRemove={onAppointmentRemove}
            onAdd={onAppointmentAdd}
          />
        </Scheduler>
      </Grid>
    </ContentCard>
  );
};

export default ScheduleCard;
