import axios from "axios";
import { LoginResponse, UserInfo } from "@common/types";

export const loginWithIdp = async (
  authCode: string
): Promise<LoginResponse> => {
  const { data } = await axios.get(`/user?auth_code=${authCode}`);

  if (data.jwtToken) {
    localStorage.setItem("accessToken", data.jwtToken);
    localStorage.setItem("accessTokenExp", `${Date.now() + 3600000}`);
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.jwtToken}`;
  }

  return data;
};

export const findUser = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<{ user_uuid: string }> => {
  const [, email] = queryKey;

  const { data } = await axios.get(`/user/find?user_email_id=${email}`);

  return data;
};

export const getUserInfo = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<UserInfo> => {
  const [, userUuid] = queryKey;

  const { data } = await axios.get(`/user/info?user_uuid=${userUuid}`);

  return data;
};

export const getMyUuid = async ({
  queryKey,
}: {
  queryKey: [string];
}): Promise<{ user_uuid: string }> => {
  const [] = queryKey;

  const { data } = await axios.get(`/user/userUUID`);

  return data;
};
