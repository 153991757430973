import React from "react";
import ListRow from "../../../components/ListRow";
import { Button, Checkbox, Chip, Flex, Text } from "@common/components";

interface MessageTargetRowProps {
  name: string;
  email: string;
  tags: string[];
}

const MessageTargetRow = ({ name, email, tags }: MessageTargetRowProps) => {
  return (
    <ListRow style={{ flexDirection: "row" }}>
      <Checkbox />

      <Flex flexDirection={"column"}>
        <Flex gap={"5px"} style={{ fontWeight: 500, margin: "0 0 10px 3px" }}>
          <Text>{name}</Text>
          <Text>{email}</Text>
        </Flex>
        <Flex gap={"5px"}>
          {tags.map((tag) => (
            <Chip key={tag}>
              <Text weight={500} color={"white"}>
                {tag}
              </Text>
            </Chip>
          ))}
          <Chip>
            <Button>
              <Text weight={500} color={"white"}>
                &nbsp;+&nbsp;
              </Text>
            </Button>
          </Chip>
        </Flex>
      </Flex>
    </ListRow>
  );
};

export default MessageTargetRow;
