import React from "react";
import styled from "styled-components";
import { FilledButton, Grid, Text } from "@common/components";

const TableRow = styled.div`
  padding: 16px;
`;

interface AutoMessageRowProps {
  text: string;
}

const AutoMessageRow = ({ text }: AutoMessageRowProps) => {
  return (
    <TableRow>
      <Grid gridTemplateColumns={"1fr 100px 80px 80px"} gap={"5px"}>
        <Text lineHeight={"34px"}>{text}</Text>
        <Text lineHeight={"34px"}>✔︎ 활성화됨</Text>
        <FilledButton style={{ padding: "5px" }}>비활성화</FilledButton>
        <FilledButton>수정</FilledButton>
      </Grid>
    </TableRow>
  );
};

export default AutoMessageRow;
