import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import layout from "./layout.json";
import home from "./home.json";

const resources = {
  ko: {
    translation: {
      layout: layout.ko,
      home: home.ko,
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
