import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import styled, { keyframes } from "styled-components";
import { useAtom } from "jotai";
import { modalListAtom } from "../utils/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLogin } from "../utils/hooks";
import { Flex, Text } from "@common/components";

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

interface ModalBackdropProps {
  children?: React.ReactNode;
}

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: ${(props: ModalBackdropProps) => (props.children ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  z-index: 10;

  background-color: rgba(0, 0, 0, 0.28);

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
`;

const Layout = () => {
  const [modalList, setModalList] = useAtom(modalListAtom);

  useLogin();

  return (
    <>
      <Header />

      <div style={{ marginBottom: "50px" }}>-</div>

      {location.pathname.includes("message") && (
        <Flex justifyContent={"center"}>
          <Text size={"1.5rem"} weight={"bold"}>
            🚧 메시지 기능은 현재 개발중입니다! 🚧
          </Text>
        </Flex>
      )}

      <Outlet />

      {modalList.map((modal, index) => (
        <ModalBackdrop
          onClick={() => setModalList([])}
          key={index}
          style={{
            zIndex: 10 + index,
          }}
        >
          {modal}
        </ModalBackdrop>
      ))}

      <ToastContainer />
    </>
  );
};

export default Layout;
