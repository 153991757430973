import React from "react";
import { Button, Flex, Text } from "@common/components";
import { Project } from "@common/types";
import { yyyymmddFormat } from "../../utils/util";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";

interface ProjectListRowProps {
  project: Project;
  onClick?: () => void;
}

const WrapperButton = styled(Button)`
  width: calc(100% - 40px);
  padding: 12px 20px;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ProjectListRow = ({ project }: ProjectListRowProps) => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useAtom(
    selectedProjectUuidAtom
  );
  const navigate = useNavigate();

  const handleProjectClick = () => {
    setSelectedProjectUuid(project.uuid || null);
    localStorage.setItem("selectedProjectUuid", project.uuid || "");
    navigate("/project/dashboard");
  };

  return (
    <WrapperButton onClick={handleProjectClick}>
      <Flex
        flexDirection={"column"}
        gap={"5px"}
        style={{
          width: "100%",
        }}
      >
        <Flex alignItems={"end"} gap={"5px"}>
          <Text size={"1.25rem"} weight={500}>
            {project.name}
          </Text>
          <Text>
            {(() => {
              const today = new Date();
              const startDate = project.startDate;
              const endDate = project.endDate;

              if (startDate > today) {
                return "예정";
              }
              if (endDate < today) {
                return "종료";
              }
              return "진행중";
            })()}
          </Text>
        </Flex>
        <Flex alignItems={"end"}>
          <Text>{`${yyyymmddFormat(project.startDate)} ~ ${yyyymmddFormat(
            project.endDate
          )}`}</Text>
        </Flex>
      </Flex>
    </WrapperButton>
  );
};

export default ProjectListRow;
