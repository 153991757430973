import React, { useState } from "react";
import {
  ContentCard,
  FilledButton,
  Flex,
  MaterialInput,
  Text,
} from "@common/components";
import { createSubproject } from "../../apis/subprojectAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { queryKeys } from "../../utils/keys";

interface SubprojectAddSectionProps {}

const SubprojectAddSection = ({}: SubprojectAddSectionProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const subprojectNameRef = React.useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const create = useMutation(createSubproject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([queryKeys.subprojects]).then(() => {
        toast.success("분과가 추가되었습니다.");
        subprojectNameRef.current!.value = "";
        //TODO: 더 다양한 쿼리 invalidate
      });
    },
  });
  const [name, setName] = useState("");

  const handleAddClick = () => {
    if (!name) {
      toast.warning("분과 이름을 입력해주세요.");
      return;
    }

    create.mutate({
      mutationKey: [selectedProjectUuid || "", name],
    });
  };

  return (
    <ContentCard>
      <ContentCard.Title>+ 지원 구분 추가</ContentCard.Title>

      <Flex>
        <MaterialInput
          label={"분과 이름"}
          inputProps={{
            onBlur: (e) => {
              setName(e.target.value);
            },
          }}
          inputRef={subprojectNameRef}
        />
      </Flex>

      <Flex justifyContent={"end"}>
        <FilledButton
          width={"100px"}
          borderRadius={"5px"}
          backgroundColor={"#6565FF"}
          hoverBackgroundColor={"#8282FF"}
          onClick={handleAddClick}
        >
          <Text>ADD</Text>
        </FilledButton>
      </Flex>
    </ContentCard>
  );
};

export default SubprojectAddSection;
