import React from "react";
import ApplicantListRow from "./ApplicantListRow";
import { Content, Grid } from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../utils/keys";
import { getApplicationsByProject } from "../../../apis/applicantAPI";

interface ApplicantListSectionProps {}

const ApplicantListSection = ({}: ApplicantListSectionProps) => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useAtom(
    selectedProjectUuidAtom
  );

  const { data } = useQuery(
    [queryKeys.applicationsByProject, selectedProjectUuid || ""],
    getApplicationsByProject
  );

  if (!data) return null;

  console.log(data);

  return (
    <Content>
      <Grid gap={"10px 0"}>
        {data.map((application) => (
          <ApplicantListRow
            name={application.applicant?.name || ""}
            email={application.applicant?.email || ""}
            interviewTime={application.interviewTime || null}
            uuid={application.uuid || ""}
            tags={[application.subproject?.name || ""]}
            key={application.uuid}
          />
        ))}
      </Grid>
    </Content>
  );
};

export default ApplicantListSection;
