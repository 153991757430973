import React from "react";
import styled from "styled-components";
import { SubprojectQuestion } from "@common/types";
import { Button, Kebab, Text } from "@common/components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSubprojectQuestion } from "../../apis/subprojectAPI";
import {
  selectedProjectUuidAtom,
  selectedSubprojectUuidAtom,
} from "../../utils/store";
import { useAtom } from "jotai";
import { queryKeys } from "../../utils/keys";

const QuestionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  background-color: white;

  padding: 10px;

  border-radius: 8px;

  box-shadow: rgb(145 158 171 / 30%) 0 0 2px 0,
    rgb(145 158 171 / 24%) 0 6px 12px -4px;

  overflow: hidden;
`;

const SettingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 10px;

  & > button {
    display: none;
  }

  &:hover {
    cursor: pointer;

    & > button {
      display: block;
    }
  }
`;

interface SubprojectQuestionListItemProps {
  question: SubprojectQuestion;
}

const SubprojectQuestionListItem = ({
  question,
}: SubprojectQuestionListItemProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);
  const [selectedSubprojectUuid] = useAtom(selectedSubprojectUuidAtom);

  const queryClient = useQueryClient();

  const questionDeletion = useMutation(deleteSubprojectQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.subproject]);
    },
  });

  const handleDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까? 답변도 함께 삭제됩니다.")) {
      questionDeletion.mutate({
        mutationKey: [
          question.uuid,
          selectedSubprojectUuid || "",
          selectedProjectUuid || "",
        ],
      });
    }
  };

  return (
    <QuestionItemWrapper>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",

          height: "100%",

          width: "5px",

          backgroundColor: "green",
        }}
      />
      <Text>{question.question}</Text>
      <SettingButtonContainer>
        <Button onClick={handleDelete}>
          <Text color={"#ff556b"}>삭제</Text>
        </Button>
        <Kebab />
      </SettingButtonContainer>
    </QuestionItemWrapper>
  );
};

export default SubprojectQuestionListItem;
