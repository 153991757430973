import React from "react";
import ApplicantListSection from "./ApplicantListSection";
import { Area, Content, Flex, PageTitle } from "@common/components";

const FormPage = () => {
  return (
    <Area>
      <Content>
        <Flex
          justifyContent={"space-between"}
          style={{
            marginBottom: "20px",
          }}
        >
          <PageTitle
            title={"지원자 목록"}
            style={{
              margin: 0,
            }}
          />

          {/*<Flex>*/}
          {/*  <Button>Filter</Button>*/}
          {/*  <Input placeholder={"keyword"} />*/}
          {/*</Flex>*/}
        </Flex>
      </Content>

      <ApplicantListSection />
    </Area>
  );
};

export default FormPage;
