import axios from "axios";
import { Project } from "@common/types";

export const getProjects = async (): Promise<Project[]> => {
  const { data } = await axios.get<Project[]>(`/project/details`);

  return data.map((project) => ({
    ...project,
    startDate: new Date(project.startDate),
    endDate: new Date(project.endDate),
  }));
};

export const createProject = async ({
  mutationKey,
}: {
  mutationKey: [string, Date, Date];
}) => {
  const [name, startDate, endDate] = mutationKey;

  const body = {
    name,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
  const { data } = await axios.post(`/project`, body);

  return data;
};

export const updateProjectName = async ({
  queryKey,
}: {
  queryKey: [string, string, string];
}) => {
  const [, uuid, name] = queryKey;

  const body = {
    name,
  };
  const { data } = await axios.put(`/project/${uuid}/name`, body);

  return data;
};

export const deleteProject = async ({
  queryKey,
}: {
  queryKey: [string, string];
}) => {
  const [, uuid] = queryKey;

  const { data } = await axios.delete(`/project/${uuid}`);

  return data;
};

export const getDetailedProjects = async (): Promise<Project[]> => {
  const { data } = await axios.get<Project[]>(`/project/details`);

  return data.map((project) => ({
    ...project,
    startDate: new Date(project.startDate),
    endDate: new Date(project.endDate),
  }));
};

export const getDetailedProject = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<Project> => {
  const [, uuid] = queryKey;
  const { data } = await axios.get<Project>(`/project/details/${uuid}`);

  return {
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
  };
};
