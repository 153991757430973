import React from "react";
import ProjectListRow from "./ProjectListRow";
import { useNavigate } from "react-router-dom";
import { Area, Button, Flex } from "@common/components";
import { useAtom } from "jotai";
import { modalListAtom } from "../../utils/store";
import ProjectCreateModal from "./ProjectCreateModal";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getProjects } from "../../apis/projectAPI";

const ProjectListPage = () => {
  const { data } = useQuery([queryKeys.projects], getProjects);
  const [, setModalList] = useAtom(modalListAtom);

  const navigate = useNavigate();

  const handleAddProjectBtnClick = () => {
    setModalList((prev) => [...prev, <ProjectCreateModal />]);
  };

  const handleProjectClick = () => {
    navigate("/project/dashboard");
  };

  return (
    <Area>
      <Flex
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={"10px"}
        wrap={"nowrap"}
        style={{
          border: "2px solid #aaaaaa",
          width: "70%",
          maxWidth: "800px",
          marginTop: "50px",
          height: "70vh",
          padding: "10px 5px",
          position: "relative",
          backgroundColor: "white",
          overflow: "scroll",
        }}
      >
        {
          data ? (
            data.length ? (
              <>
                {data.map((project) => (
                  <ProjectListRow
                    key={project.uuid}
                    project={project}
                    onClick={handleProjectClick}
                  />
                ))}
                <Button
                  onClick={handleAddProjectBtnClick}
                  fontWeight={"bold"}
                  style={{ position: "absolute", right: "10px", bottom: "5px" }}
                >
                  + 새 프로젝트
                </Button>
              </>
            ) : (
              <Button
                onClick={handleAddProjectBtnClick}
                width={"100%"}
                height={"100%"}
              >
                +
                <br />새 프로젝트 추가하기
              </Button>
            )
          ) : null
          // TODO: 스피너 추가
        }
      </Flex>
    </Area>
  );
};

export default ProjectListPage;
