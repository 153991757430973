import React from "react";
import DashboardCard from "./DashboardCard";
import { Flex, Text } from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getApplicationsByProject } from "../../apis/applicantAPI";

interface DashboardApplicationNumberProps {}

const DashboardApplicationNumber = ({}: DashboardApplicationNumberProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.applicationsByProject, selectedProjectUuid || ""],
    getApplicationsByProject
  );

  if (!data) return null;

  return (
    <DashboardCard
      to={"/project/application/form"}
      style={{ flex: "1 1 0", aspectRatio: "1.618" }}
    >
      <DashboardCard.Title>지원자</DashboardCard.Title>

      <Flex
        style={{ width: "100%", flexGrow: 1 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text size={"2.25rem"} weight={500}>
          {data.length}
        </Text>
      </Flex>
    </DashboardCard>
  );
};

export default DashboardApplicationNumber;
