import React from "react";
import AlertSection from "./AlertSection";
import OverallSection from "./OverallSection";
import { Area, Padding } from "@common/components";

const OverallPage = () => {
  return (
    <Area>
      <OverallSection />

      <Padding height={"40px"} />

      <AlertSection />
    </Area>
  );
};

export default OverallPage;
