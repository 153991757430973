import React from "react";
import DashboardCard from "../../../dashboard/DashboardCard";
import { Text } from "@common/components";

interface ApplicationPersonalQuestionCardProps {
  question: string;
  answer: string;
}

const ApplicantPersonalQuestionCard = ({
  question,
  answer,
}: ApplicationPersonalQuestionCardProps) => {
  return (
    <DashboardCard>
      <DashboardCard.Title>{question}</DashboardCard.Title>
      <Text>{answer}</Text>
    </DashboardCard>
  );
};

export default ApplicantPersonalQuestionCard;
