import React, { useEffect, useState } from "react";
import { Area } from "@common/components";
import { useAtom } from "jotai";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { getApplicationsByProject } from "../../apis/applicantAPI";
import ScheduleCard from "./ScheduleCard";
import { nullDate } from "../../utils/util";

const SchedulePage = () => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useAtom(
    selectedProjectUuidAtom
  );
  const [schedule, setSchedule] = useState<
    {
      text: string;
      startDate: Date;
      endDate: Date;
      uuid: string;
    }[]
  >([]);
  const [notAppointed, setNotAppointed] = useState<
    {
      text: string;
      uuid: string;
    }[]
  >([]);

  const { data } = useQuery(
    [queryKeys.applicationsByProject, selectedProjectUuid || ""],
    getApplicationsByProject
  );

  useEffect(() => {
    if (!data) return;
    const appointedList = data.filter((item) => item.interviewTime > nullDate);
    const notAppointedList = data.filter(
      (item) => !(item.interviewTime > nullDate)
    );

    const newAppointedList = appointedList.map((item) => ({
      text: item.applicant?.name || "",
      startDate: item.interviewTime,
      endDate: new Date(item.interviewTime.getTime() + 1000 * 1800),
      uuid: item.uuid,
    }));

    console.log("newList", newAppointedList);

    setSchedule(newAppointedList);

    const newNotAppointedList = notAppointedList.map((item) => ({
      text: item.applicant?.name || "",
      uuid: item.uuid,
    }));

    setNotAppointed(newNotAppointedList);
  }, [data]);

  if (!data) return null;

  return (
    <Area>
      <ScheduleCard schedule={schedule} notAppointed={notAppointed} />
    </Area>
  );
};

export default SchedulePage;
