import React from "react";
import { useNavigate } from "react-router-dom";
import ListRow from "../../../components/ListRow";
import { Button, Chip, Flex, Text } from "@common/components";
import { colorSet } from "@common/theme";
import { nullDate } from "../../../utils/util";

interface ApplicantListRowProps {
  name: string;
  email: string;
  interviewTime: Date;
  uuid: string;
  tags: string[];
}

const ApplicantListRow = ({
  name,
  email,
  interviewTime,
  uuid,
  tags,
}: ApplicantListRowProps) => {
  const navigate = useNavigate();

  const handleInspectClick = () => {
    navigate(`/project/application/personal?uuid=${uuid}`);
  };

  return (
    <ListRow>
      <Flex justifyContent={"space-between"}>
        <Flex flexDirection={"column"}>
          <Flex gap={"5px"} style={{ fontWeight: 500, margin: "0 0 10px 3px" }}>
            <Text>{name}</Text>
            <Text>{email}</Text>
          </Flex>
          <Flex gap={"5px"} style={{ fontWeight: 500, margin: "0 0 10px 3px" }}>
            <Text>{`면접 일정: ${
              interviewTime > nullDate ? interviewTime.toLocaleString() : "미정"
            }`}</Text>
          </Flex>
          <Flex gap={"5px"}>
            {tags.map((tag) => (
              <Chip key={tag}>
                <Text weight={500} color={"white"}>
                  {tag}
                </Text>
              </Chip>
            ))}
            {/*<Chip>*/}
            {/*  <Button>*/}
            {/*    <Text weight={500} color={"white"}>*/}
            {/*      &nbsp;+&nbsp;*/}
            {/*    </Text>*/}
            {/*  </Button>*/}
            {/*</Chip>*/}
          </Flex>
        </Flex>
        <Button color={colorSet.primary} onClick={handleInspectClick}>
          <Text>상세보기</Text>
        </Button>
      </Flex>
    </ListRow>
  );
};

export default ApplicantListRow;
