import React from "react";
import DashboardCard from "./DashboardCard";
import { Flex, Text } from "@common/components";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../utils/keys";
import { selectedProjectUuidAtom } from "../../utils/store";
import { useAtom } from "jotai";
import { getDetailedProject } from "../../apis/projectAPI";

interface DashboardStatusCardProps {}

const DashboardStatusCard = ({}: DashboardStatusCardProps) => {
  const [selectedProjectUuid] = useAtom(selectedProjectUuidAtom);

  const { data } = useQuery(
    [queryKeys.project, selectedProjectUuid || ""],
    getDetailedProject
  );

  if (!data) return null;

  return (
    <DashboardCard style={{ flex: "1 1 0", aspectRatio: "1.618" }}>
      <DashboardCard.Title>기간</DashboardCard.Title>

      <Flex
        style={{ width: "100%", flexGrow: 1 }}
        flexDirection={"column"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
      >
        <Text>
          {data.startDate.toLocaleDateString()} ~{" "}
          {data.endDate.toLocaleDateString()}
        </Text>
        <Text size={"1.5rem"} weight={500}>
          {(() => {
            const today = new Date();
            const startDate = data.startDate;
            const endDate = data.endDate;

            if (startDate > today) {
              return "예정";
            }
            if (endDate < today) {
              return "종료";
            }
            return "진행중";
          })()}
        </Text>
      </Flex>
    </DashboardCard>
  );
};

export default DashboardStatusCard;
