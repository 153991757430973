import React from "react";
import DashboardCard from "../../dashboard/DashboardCard";
import MessageTargetRow from "./MessageTargetRow";
import { Flex, Grid } from "@common/components";

interface MessageTargetCardProps {}

const MessageTargetCard = ({}: MessageTargetCardProps) => {
  return (
    <DashboardCard>
      <Flex justifyContent={"space-between"}>
        <DashboardCard.Title>지원자 목록</DashboardCard.Title>
        <Flex></Flex>
      </Flex>

      <Grid gap={"10px"}>
        <MessageTargetRow
          name={"홍길동"}
          email={"gildong@gm.gist.ac.kr"}
          tags={["태그1", "태그2"]}
        />
        <MessageTargetRow
          name={"홍길동"}
          email={"gildong@gm.gist.ac.kr"}
          tags={["태그1", "태그2"]}
        />
        <MessageTargetRow
          name={"홍길동"}
          email={"gildong@gm.gist.ac.kr"}
          tags={["태그1", "태그2"]}
        />
      </Grid>
    </DashboardCard>
  );
};

export default MessageTargetCard;
