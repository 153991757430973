import React from "react";
import { Flex, Text } from "@common/components";

interface AlertRowProps {
  text: string;
}

const AlertRow = ({ text }: AlertRowProps) => {
  return (
    <Flex
      style={{
        borderRadius: "8px",
        backgroundColor: "#FFBBBB",
        padding: "0 10px",
      }}
    >
      <Text>{text}</Text>
    </Flex>
  );
};

export default AlertRow;
