import axios from "axios";
import { Subproject } from "@common/types";

export const getSubprojects = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<Subproject[]> => {
  const [, uuid] = queryKey;

  const { data } = await axios.get(`/subproject/?project_uuid=${uuid}`);

  return data;
};

export const createSubproject = async ({
  mutationKey,
}: {
  mutationKey: [string, string];
}) => {
  const [projectUuid, name] = mutationKey;

  const body = {
    name,
  };
  const { data } = await axios.post(
    `/subproject?project_uuid=${projectUuid}`,
    body
  );

  return data;
};

export const deleteSubproject = async ({
  mutationKey,
}: {
  mutationKey: [string, string];
}) => {
  const [uuid, projectUuid] = mutationKey;

  const { data } = await axios.delete(
    `/subproject/${uuid}?project_uuid=${projectUuid}`
  );

  return data;
};

export const getSubproject = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<Subproject> => {
  const [, uuid] = queryKey;

  const { data } = await axios.get(`/subproject/${uuid}`);

  return data;
};

export const createSubprojectQuestion = async ({
  mutationKey,
}: {
  mutationKey: [string, string, string];
}) => {
  const [subprojectUuid, projectUuid, question] = mutationKey;

  const body = {
    question,
  };
  const { data } = await axios.post(
    `/subproject/${subprojectUuid}/question?project_uuid=${projectUuid}`,
    body
  );

  return data;
};

export const deleteSubprojectQuestion = async ({
  mutationKey,
}: {
  mutationKey: [string, string, string];
}) => {
  const [questionUuid, subprojectUuid, projectUuid] = mutationKey;

  const { data } = await axios.delete(
    `/subproject/${subprojectUuid}/question/${questionUuid}?project_uuid=${projectUuid}`
  );

  return data;
};
