import "devextreme/dist/css/dx.light.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import SignInPage from "./pages/auth/SignInPage";
import ProjectListPage from "./pages/project/ProjectListPage";
import OverallPage from "./pages/overalll/OverallPage";
import ManagerPage from "./pages/manager/ManagerPage";
import SubprojectPage from "./pages/subproject/SubprojectPage";
import Dashboard from "./pages/dashboard/Dashboard";
import FormPage from "./pages/application/form/FormPage";
import QuestionPage from "./pages/application/question/QuestionPage";
import ApplicantPersonalPage from "./pages/application/form/personal/ApplicantPersonalPage";
import MessageSendPage from "./pages/message/send/MessageSendPage";
import MessageConfigurePage from "./pages/message/configure/MessageConfigurePage";
import { isLoggedInAtom } from "./utils/store";
import { useAtom } from "jotai";
import { useLogin, useProject } from "./utils/hooks";
import SubprojectDetailPage from "./pages/subproject/SubprojectDetailPage";
import SchedulePage from "./pages/schedule/SchedulePage";

function App() {
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  //TODO: 로그인 좀 더 엄밀하게

  useLogin();
  useProject();

  return (
    <Routes>
      <Route element={<Layout />}>
        {isLoggedIn ? (
          <>
            <Route path="*" element={<ProjectListPage />} />

            <Route path="/project/dashboard" element={<Dashboard />} />

            <Route path="/project-list" element={<ProjectListPage />} />

            <Route path="/project/overall" element={<OverallPage />} />
            <Route path="/project/manager" element={<ManagerPage />} />
            <Route path="/project/subproject" element={<SubprojectPage />} />
            <Route
              path="/project/subproject/detail"
              element={<SubprojectDetailPage />}
            />

            <Route path="/project/application/form" element={<FormPage />} />
            <Route
              path="/project/application/personal"
              element={<ApplicantPersonalPage />}
            />
            <Route
              path="/project/application/question"
              element={<QuestionPage />}
            />

            <Route path="/project/message/send" element={<MessageSendPage />} />
            <Route
              path="/project/message/auto"
              element={<MessageConfigurePage />}
            />
            <Route path="/project/schedule" element={<SchedulePage />} />
          </>
        ) : (
          <>
            <Route path="*" element={<SignInPage />} />
            <Route path="/sign-in" element={<SignInPage />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
